.DeleteQuizModal{
    margin: auto;
    margin-top: 30px;
    width: 90%;

    &__Header{
        font-size: 20px;
        margin-bottom: 50px;
    }

    &__Buttons{
        width: 90%;
        position: absolute;
        bottom: 20px;
    }
}