.PresenterQuiz{
    position: relative;
    width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__Start{
        text-align: center;
        p{
            font-size: 22px;
            font-weight: bold;
        }
        p:nth-child(2){
            font-weight: normal;
            font-size: 16px;
        }

        .PresenterStartButton{
            font-size: 16px;
            font-weight: bold;
        }
    }

    &__Question{
        width: 100%;
        &--Title{
            text-align: center;
            font-size: 22px;
            font-weight: bold;
        }

        &--Answer{
            font-size: 16px;
            font-weight: bold;
        }

        .Red{
            background: red;
        }

        .Green{
            background: green;
        }

        &--Button{
            margin-top: 30px;
        }
    }

    &__Progress{
        width: 100%;
        p{
            margin-left: 10px;
            font-weight: bold;
            width: 30%;
            display: inline;
        }
        div{
            display: inline-block;
            width: 70%;
            height: 15px;
            margin: 0;
            background-color: gray;

            div{
                @include fade-in();
                width: 100%;
                background-color: lightblue;
                transition: max-width .7s;
            }
        }
    }

    &__EmptyState{
        @include fade-in();
        margin-top: 50px;
        text-align: center;
        p{
            font-size: 15px;
            font-weight: bold;
        }
    }

    &__Scores{
        width: 100%;
    }

    &__Score{
        display: flex;
        justify-content: space-between;
        align-items: center;

        p:nth-child(1){
            width: 210px;
            overflow: hidden;
        }
        p:nth-child(2){
            width: 60px;
            font-weight: bold;
            text-align: right;
        }

        &:nth-child(1){
            p{
                font-size: 18px;
                font-weight: bold;
            }
        }
    }

    &__FixedFooter{
        width: 100%;
        position: fixed;
        bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &--Button{
            margin: 0 10px;
        }

        &--ParticipantCounter{
            margin-left: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 40px;

            p{
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
}
