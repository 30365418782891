.PresenterModule{
    padding: 0 10px;

    &__Title{
        text-align: center;
        p{
            margin: 0;
            font-size: 14px;
        }
        p:nth-child(1){
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }

    &__Quizes{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .QuizCard{
            @include fade-in();
            margin-bottom: 10px;
        }
    }

    &__CreateButton{
        margin-top: 10px;
        margin-left: auto;
    }

    &__StartingQuiz{
        margin-top: 30px;
        @include fade-in();
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }
}