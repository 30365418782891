.Header {
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 70px;
    img{
        width: 160px;
        height: 25px;
    }

    &__UserInfo{
        display: flex;
        align-items: center;
        cursor: pointer;
        p{
            margin-right: 20px;
        }
    }

    &__UserInfoPopup{
        width: 200px;
        text-align: center;
    }
}