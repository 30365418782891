.CreateQuizModal{
    margin: 10px;
    text-align: center;

    &__Form{
        margin: auto;
        width: 80% !important;
        min-height: 600px !important;
    }

    .ui{
        &-form{
            justify-content: flex-start;
            &__field{
                width: 100%;
            }
        }

        &-box{
            width: 100%;
            height: 50px;
        }

        &-button{
            max-width: unset
        }
    }

    &__Vote{
        @include fade-in()
    }

    &__DividerGroup{
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--Divider{
            width: 90%;
            
            .DividerContent{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 140px;

                p{
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        
        &--TrashIcon{
            transition: all .1s;
            cursor: pointer;
            width: 33px;
            &:hover{
                transform: scale(1.4) !important;
            }
        }
    }
    
    &__QuestionLabel{
        width: 100%;

        p:nth-child(1){
            font-size: 20px;
            font-weight: bold;
            text-align: left;
        }
    }

    &__AnswerGroup{
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .ui-input{
            max-width: 80%;
        }

        &--DeleteIcon{
            transition: all .1s;
            cursor: pointer;
            width: 33px;
            &:hover{
                transform: scale(1.4) !important;
            }
        }

        &--CorrectAnswer{
            cursor: pointer;
            transition: all .1s;
            &:hover{
                transform: scale(1.4) !important;
            }

        }
    }

    &__AddAnswer{
        width: 80%;
        height: 40px !important;
        margin-top: 10px;
        font-weight: bold;
    }

    &__Points{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        padding: 0 20px;

        p{
            font-size: 15px;
            font-weight: bold;
        }

        .PointsMenu{
            li{
                width: 200px;
                font-size: 15px;
                font-weight: bold;
            }
        }
    }

    &__Errors{
        margin: 20px 0;
        text-align: left;
    }

    &__Error{
        margin-bottom: 10px;

        &--Message{
            display: inline;
            margin-left: 10px;
            font-weight: bold;
        }
    }

    &__TimeControl, &__NrOfVotes, &__NrOfMessagesAllowed{
        margin: 20px 0;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p:nth-child(1){
            text-align: left;
            width: 40%;
        }

        p:nth-child(3){
            width: 5%;
        }

        p{
            font-size: 15px;
            font-weight: bold;
        }
    }

    &__QuestionButtons{
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
    }

    &__QuestionButton{
        max-width: unset !important;
        width: 40% !important;
        height: 50px !important;
        font-size: 14px;
        font-weight: bold;
        span {
            margin-right: 10px;
        }
    }

    &__Buttons{
        margin-top: auto !important;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__Button{
        font-size: 15px;
        font-weight: bold;
        height: 50px !important;
        width: 200px;
    }
}