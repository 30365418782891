
.Auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;

    img {
      width: 220px;
      margin-bottom: 40px;
    }

    .desc {
      max-width: 440px;
      margin-bottom: 30px;
      text-align: center;
    }

    .container {
      .ui-button {
        display: block;
        width: 220px;

        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  .AuthStart {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__header{
      margin-bottom: 50px !important;

      &--message{
        font-weight: bold;
      }

      &--help{
        font-weight: normal;
        display:block;
        margin-top: 5px;
        margin-bottom: 15px;
      }

    }

    .ui-flex {
      max-width: 500px;
      width: 60%;
    }
    .ui-box {
      min-width: 100%;
    }
  }

  .AuthReset {
    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }