.ParticipantModule{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__Ready{
        @include fade-in();
        text-align: center;
        p:nth-child(1){
            font-size: 22px;
            font-weight: bold;
        }
    }

    &__Question{
        @include fade-in();
        font-size: 22px
    }

    &__Debug{
        border: 1px solid red;
        width: 100%;
        margin-top: 20px;
    }

    &__VoteResults{

        border-top: 2px solid #6264A7;
        margin-top: 30px;
        padding-top: 15px;

        &--Answer{
            text-align: left;
            font-weight: bold;
        }

        &--Progress{
            width: 100%;
            text-align: left;

            p{
                margin-left: 10px;
                font-weight: bold;
                width: 30%;
                display: inline;
            }
            div{
                display: inline-block;
                width: 70%;
                height: 15px;
                margin: 0;
                background-color: gray;

                div{
                    @include fade-in();
                    width: 100%;
                    background-color: lightblue;
                    transition: max-width .7s;
                }
            }

            .Red{
                background: red;
            }

            .Green{
                background: green;
            }


        }
    }

    &__Vote{
        text-align: center;
        width: 100%;
    }

    &__Answer{
        @include fade-in();
        width: 200px;
        margin: auto;
    }

    &__MessageroundInput{
        &--Text{
            box-shadow: 1px 2px #6264A7;
            height: 80px !important;
        }

        &--Button{
            margin-top: 30px;
        }
    }

    &__Button{
        width: 100%;
        min-height: 30px;
        height: auto !important;
        margin-bottom: 10px;
        font-weight: bold;
    }

    &__SubmittedAnswer{
        @include fade-in();
        text-align: center;
        p{
            display: inline;
            font-size: 16px;
            font-weight: bold;
        }
        p:nth-child(1){
            font-weight: normal;
        }
    }

    &__Rank{
        div{
            display: flex;
            align-items: center;
            font-size: 18px;
            p:nth-child(2){
                margin-left: 15px;
                font-weight: bold;
            }
        }

        &--LeaderboardTitle{
            margin-top: 20px;
            font-weight: bold;
        }

        &--Leaderboard{
            display: flex;
            justify-content: space-between;
            align-items: center;

            p:nth-child(1){
                width: 190px;
                font-size: 16px;
                overflow: hidden;
            }
            p:nth-child(2){
                width: 60px;
                font-size: 16px;
                font-weight: bold;
                text-align: right;
            }

            &:nth-child(1){
                p{
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        }

    &__Scores{
        width: 100%;
    }

    &__NicknameInput{
        .ui{
            &-form{
                width: 270px;
            }

            &-box{
                width: 270px;
                height: 50px;
            }

            &-icon{
                margin-left: auto;
            }

            &-button{
                height: 30px;
            }
        }
    }

}
