@import '~sass-mq';
body {
    margin: 5em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }  

.App {
    text-align: center;
}
h1 {
color: gray;
}
h2 {
font-size: .8em;
}
.PropName {
font-weight: bold;
color: #6264A7;
}
.Logo {
font-size: 45pt;
color: #6264A7;
}
.Error {
color: red;
}

.ui-provider{
  background-color: transparent !important;
}