.ChannelTabContent{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100vh;
    

    &__CreateButton{
        align-self: flex-end;
    }

    &__MyQuizes{
        align-self: center;
        font-size: 18px;
        font-weight: bold;
    }

    &__Grid{
        padding: 30px;
        display: grid;
        grid-template-columns: repeat(auto-fit, 300px);
        grid-gap: 20px;
        justify-content: start;
    }
}