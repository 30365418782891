.QuizCard{

    &__Title{
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        height: 35px;
    }

    &__Questions{
        margin-top: 5px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    &__Buttons{
        display: flex;
        flex-direction: column;
    }

    &__Button{
        margin-bottom: 10px;
        display: block !important;
    }

    @include mq($until: 280px){
        justify-self: center;
        width: 260px !important;
        height: 100px !important;
        padding: 5px !important;

        &__Title{
            font-size: 16px;
        }

        &__Questions{
            font-size: 12px;
            margin-bottom: 10px;
        }

        &__Button{
            font-size: 14px;
            width: 80%;
            min-height: 22px !important;
            font-weight: bold;
            align-self: center;
        }
    }
}