@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@mixin fade-in {
	-webkit-animation: fadein .7s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein .7s; /* Firefox < 16 */
	-ms-animation: fadein .7s; /* Internet Explorer */
	-o-animation: fadein .7s; /* Opera < 12.1 */
	animation: fadein .7s;
}