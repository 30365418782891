.Dashboard{
        text-align: center;
        min-height: 95vh;
        &__Grid{
                padding: 30px;
                margin-top: 50px;
                display: grid;
                grid-template-columns: repeat(auto-fit, 400px);
                grid-gap: 20px;
                justify-content: start;
        }
}