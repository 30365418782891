.QuestionTypePopup{
    width: 172px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .ui-button{
        margin-bottom: 10px;
        width: 100%;
        height: 60px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 15px;
    }
}